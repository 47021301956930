<template>
  <div class="oilLineChart" ref="oilLineChart"></div>
</template>

<script>
import ChartTimeJson from 'assets/json/chartTIme.json'

export default {
  name: 'OilLineChart',
  props: {
    lineChartTheme: Object,
    historyInfo: Object
  },
  data: () => {
    return {
      chartInstance: null,
      chartTimeList: ''
    }
  },
  watch: {
    historyInfo() {
      this.updateChart()
    }
  },
  methods: {
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.oilLineChart)
      this.chartShowLoading()
      this.getChartTimeJson()
      this.updateChart()
    },
    // 更新图表
    updateChart() {
      const option = {
        grid: {
          height: '75%',
          top: '21%',
          left: '2%',
          right: '2%',
          bottom: '0%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: this.lineChartTheme.legend,
          top: '4%',
          right: '3%',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)',
            fontFamily: 'Source Han Sans CN'
          }
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: this.historyInfo.time,
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(124, 197, 247, 0.3)' // 坐标轴线线的颜色
              }
            },
            axisTick: {
              show: false // 去除刻度线
            },
            axisLabel: {
              // 坐标轴刻度标签的相关设置
              color: '#7CC5F7',
              fontFamily: 'Source Han Sans CN',
              fontSize: 12
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              // 坐标轴刻度标签的相关设置
              show: false
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(124, 197, 247, 0.3)'
              }
            }
          }
        ],
        series: [
          {
            name: this.lineChartTheme.legend[0],
            type: 'line',
            stack: '总量',
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: this.lineChartTheme.series[0].itemColor, // 折点颜色
                lineStyle: {
                  color: this.lineChartTheme.series[0].lineColor // 折线颜色
                }
              }
            },
            areaStyle: {
              color: this.lineChartTheme.series[0].areaColor
            },
            emphasis: {
              focus: 'series',
              itemStyle: {
                color: 'RGBA(242, 198, 37, 1)',
                borderWidth: 20
              }
            },
            markArea: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    // 背景渐变色
                    offset: 0,
                    color: 'rgba(10, 45, 78, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(28, 62, 94, 0.5)'
                  }
                ])
              },
              data: this.chartTimeList
            },
            data: this.historyInfo.yFirstData
          },
          {
            name: this.lineChartTheme.legend[1],
            type: 'line',
            stack: '总量',
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: this.lineChartTheme.series[1].itemColor, // 折点颜色
                lineStyle: {
                  color: this.lineChartTheme.series[1].lineColor // 折线颜色
                }
              }
            },
            areaStyle: {
              color: this.lineChartTheme.series[1].areaColor
            },
            emphasis: {
              focus: 'series',
              itemStyle: {
                color: 'RGBA(242, 198, 37, 1)',
                borderWidth: 20
              }
            },
            markArea: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    // 背景渐变色
                    offset: 0,
                    color: 'rgba(10, 45, 78, 0.5)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(28, 62, 94, 0.5)'
                  }
                ])
              },
              data: this.chartTimeList
            },
            data: this.historyInfo.ySecondData
          }
        ]
      }

      this.chartInstance.hideLoading()
      this.chartInstance.setOption(option)
    },
    // 获取背景阴影的json
    getChartTimeJson() {
      this.chartTimeList = ChartTimeJson.list
    },
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('oilBarChart'))
          .resize()
      }
    }
  },
  mounted() {
    this.initChart()
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.oilLineChart {
  width: 380px;
  height: 264px;
  background: rgba(23, 78, 128, 0.22);
}
</style>
