<template>
  <div class="oilHeaderContainer">
    <div class="leftLine"></div>
    <div class="rightLine"></div>
    <div class="header">
      <div class="dateCard">
        <p class="date">{{ this.year }} {{ this.week }} {{ this.time }}</p>
        <p class="card">{{ headerInfo.device_name }}</p>
        <p class="card">{{ headerInfo.nickname }}</p>
      </div>
      <p class="headerName">{{ headerInfo.name }}</p>
      <div class="oilfieldinfo">{{ headerInfo.oilfield }}</div>
      <div class="logOut">
        <div class="in">
          <i class="iconfont icon-icon-test"></i>
          <span>{{ $store.state.loginInfo.name }}</span>
          <i class="iconfont icon-jiantou"></i>
        </div>
        <div class="out" @click="logout">
          <i class="iconfont icon-tuichu"></i><span>退出登陆</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OilHeader',
  props: {
    headerInfo: Object
  },
  data: () => {
    return {
      year: '',
      week: '',
      time: ''
    }
  },
  methods: {
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    setDate() {
      this.year = this.$moment().format('LL')
      this.week = this.currentDay = this.$moment().format('dddd')
      this.time = this.$moment().format('a HH:mm')
    }
  },
  mounted() {
    this.setDate()
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
@import '~assets/css/icon.css';

.oilHeaderContainer {
  position: relative;
  width: 100%;
  height: 83px;
  .leftLine {
    position: absolute;
    top: 46px;
    left: 25%;
    width: 200px;
    height: 13px;
    background: url('~assets/img/common/line2.png');
    transform: rotateY(180deg);
    background-repeat: no-repeat;
    animation: 1.7s 0.1s living linear infinite backwards normal;
  }
  .rightLine {
    position: absolute;
    top: 46px;
    right: 25%;
    width: 200px;
    height: 13px;
    background: url('~assets/img/common/line.png');
    background-repeat: no-repeat;
        animation: 1.7s 0.1s living2 linear infinite backwards normal;
  }
  .header {
    padding: 0 46px 0 48px;
    width: 100%;
    height: inherit;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: url('~assets/img/common/header.png');
    background-size: cover;
    .dateCard {
      width: auto;
      height: 50px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      .card {
        margin-left: 64px;
      }
    }
    .headerName {
      position: absolute;
      left: 52%;
      top: 21px;
      transform: translate(-52%);
      width: 360px;
      height: 36px;
      align-self: center;
      font-size: 36px;
      font-family: SourceHanSansCN-Bold;
      font-weight: bold;
      color: #ffffff;
      // background: linear-gradient(0deg, #d7d7d7 0%, #ffffff 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    .oilfieldinfo {
      position: absolute;
      left: 69%;
      top: 13px;
      width: auto;
      height: 50px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .logOut {
      width: auto;
      height: auto;
      align-self: flex-end;
      cursor: pointer;
      &:hover {
        .out {
          visibility: visible;
        }
      }
      .in {
        width: auto;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
          color: #fff;
          font-size: 22px;
        }
        span {
          width: auto;
          font-size: 20px;
          font-family: SourceHanSansCN-Bold;
          color: #ffffff;
        }
      }
      .out {
        width: auto;
        height: 40px;
        visibility: hidden;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #0a2541;
        i {
          color: rgba(255, 255, 255, 0.48);
          font-size: 22px;
        }
        span {
          width: auto;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.48);
        }
      }
    }
  }
}
@keyframes living {
  0% {
    left: 25%;
  }

  25% {
    left: 18%;
  }
  50% {
    left: 12%;
  }

  75% {
    left: 6%;
  }
  100% {
    left: 0%;
  }
}
@keyframes living2 {
  0% {
    right:25%;
  }

  25% {
    right: 18%;
  }
  50% {
    right: 12%;
  }

  75% {
    right: 6%;
  }
  100% {
    right: 0%;
  }
}
// @keyframes living {
//   0% {
//     transform: scaleX(-1);
//     transform-origin: 0px;
//   }

//   50% {
//     transform: scaleX(-0.2);
//     transform-origin: 0px;
//   }

//   100% {
//     transform: scaleX(-1);
//     transform-origin: 0px;
//   }
// }
</style>
