<template>
  <div class="page">
    <oil-header :headerInfo="headerInfo" @click="test"></oil-header>
    <input-pw-popup
      ref="pwPopup"
      @handleYesEvent="handleYesEvent"
    ></input-pw-popup>
    <input-modify-popup
      ref="mdPopup"
      @handleModifyEvent="handleModifyEvent"
    ></input-modify-popup>
    <div class="wrapper">
      <div class="leftFrame">
        <!-- 设备运行状态 -->
        <div class="machineState">
          <oil-title>
            <span slot="left">设备运行状态</span>
          </oil-title>
          <div class="msInfo">
            <div class="left">
              <div class="up">
                <p class="num">{{ monitorInfo.T_Production }}</p>
                <p class="text">采液量<span>m³</span></p>
              </div>
              <div class="down">
                <p class="text">耗电量<span>kWh</span></p>
                <p class="num">{{ monitorInfo.T_PowerC }}</p>
              </div>
            </div>
            <div class="middle">
              <el-progress
                type="circle"
                :width="progress.width"
                color="#00DAFC"
                :stroke-width="progress.stroke"
                :format="stateProgressFun"
                :percentage="progress.centage"
              ></el-progress>
            </div>
            <div class="right">
              <div class="up">
                <p class="num">{{ monitorInfo.T_Poweron_T }}</p>
                <p class="text">上电时长<span>days</span></p>
              </div>
              <div class="down">
                <p class="iconU">
                  <i class="iconfont icon-shang1"></i
                  >{{ monitorInfo.Uplink_F }}Hz
                </p>
                <p class="iconD">
                  <i class="iconfont icon-arrow-downward-outline"></i>
                  {{ monitorInfo.Downlink_F }}Hz
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 基本监控信息 -->
        <div class="machineMonitorInfo">
          <oil-title>
            <span slot="left">基本监控信息</span>
          </oil-title>
          <div class="infoList">
            <div class="listHeader">
              <div class="headerName">名称</div>
              <div class="headerValue">设备信息</div>
            </div>
            <div class="listBody">
              <div class="listItem">
                <div class="itemName">工作状态</div>
                <div class="itemValue">
                  {{ monitorInfo.Work_S | formatWorkS }}
                </div>
              </div>
              <div class="listItem">
                <div class="itemName">上行频率</div>
                <div class="itemValue">{{ monitorInfo.Uplink_F }}Hz</div>
              </div>
              <div class="listItem">
                <div class="itemName">下行频率</div>
                <div class="itemValue">{{ monitorInfo.Downlink_F }}Hz</div>
              </div>
              <div class="listItem">
                <div class="itemName">上行电流</div>
                <div class="itemValue">{{ monitorInfo.Up_Mean_Urrent }}A</div>
              </div>
              <div class="listItem">
                <div class="itemName">下行电流</div>
                <div class="itemValue">{{ monitorInfo.Down_Mean_Urrent }}A</div>
              </div>
              <div class="listItem">
                <div class="itemName">冲次</div>
                <div class="itemValue">{{ monitorInfo.Stroke_T }}次/min</div>
              </div>
              <div class="listItem">
                <div class="itemName">冲程</div>
                <div class="itemValue">{{ monitorInfo.device_stroke }}cm</div>
              </div>
              <div class="listItem">
                <div class="itemName">泵径</div>
                <div class="itemValue">{{ monitorInfo.device_diameter }}mm</div>
              </div>
              <div class="listItem">
                <div class="itemName">散热器温度</div>
                <div class="itemValue">{{ monitorInfo.Radiator_T }}°C</div>
              </div>
              <div class="listItem">
                <div class="itemName">控制柜温度</div>
                <div class="itemValue">{{ monitorInfo.Control_T }}°C</div>
              </div>
              <div class="listItem">
                <div class="itemName">直流母线电流平均值</div>
                <div class="itemValue">{{ monitorInfo.DC_Main_Ia }}A</div>
              </div>
              <div class="listItem">
                <div class="itemName">直流电压平均值</div>
                <div class="itemValue">{{ monitorInfo.DC_Va }}V</div>
              </div>
              <div class="listItem">
                <div class="itemName">输入电压有效值</div>
                <div class="itemValue">{{ monitorInfo.Input_Ve }}V</div>
              </div>
              <div class="listItem">
                <div class="itemName">累计耗电量</div>
                <div class="itemValue">{{ monitorInfo.T_PowerC }}kWh</div>
              </div>
              <div class="listItem">
                <div class="itemName">累计采液量</div>
                <div class="itemValue">{{ monitorInfo.T_Production }}m³</div>
              </div>
              <div class="listItem">
                <div class="itemName">设备状态</div>
                <div class="itemValue">{{ monitorInfo.Device_State_Name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middleFrame">
        <!-- 中控设置 -->
        <div class="controlSettings">
          <div class="setLeft">
            <div class="setTips">
              <div class="tipsOn"></div>
              <p class="tipsText">开</p>
              <div class="tipsOff"></div>
              <p class="tipsText">关</p>
            </div>
            <div class="setList">
              <div class="icon-mainG" :style="monitorInfo.Main_G | mainGState">
                <span>主开关状态</span>
              </div>
              <div
                class="icon-precharge"
                :style="monitorInfo.Precharge_G | prechargeState"
              >
                <span>预充电开关状态</span>
              </div>
              <div class="icon-fanG" :style="monitorInfo.Fan_G | fanGState">
                <span>风机开关状态</span>
              </div>
            </div>
          </div>
          <div class="setRight">
            <div class="setList">
              <div class="icon-start setOffColor" @click="start">启动</div>
              <div class="icon-stop setOffColor" @click="stop">停止</div>
              <div class="icon-up setOffColor" @click="uplink">上行</div>
              <div class="icon-down setOffColor" @click="downlink">下行</div>
              <div class="icon-f setOffColor" @click="stroke">冲次</div>
            </div>
            <div class="oilBg"></div>
          </div>
          <div class="back" @click="$router.back()">
            <div class="opt"></div>
            <div class="text">返回上一级</div>
          </div>
          <div class="refresh" @click="refresh">
            <div class="opt"></div>
            <div class="text">更新数据</div>
          </div>
          <div class="history" @click="history">
            <div class="opt"></div>
            <div class="text">历史数据</div>
          </div>
        </div>
        <!-- 中控数据可视化图 -->
        <div class="controlCharts">
          <div class="left">
            <div class="title">
              <p>故障报警信息</p>
            </div>
            <div class="alarmList">
              <div class="listHeader">
                <div class="headerName">名称</div>
                <div class="headerTime">时间</div>
                <div class="headerValue">风险预警</div>
              </div>
              <div class="listBody">
                <div class="listItem" v-for="item in alarmInfo" :key="item.id">
                  <div class="itemName">{{ item.faultname }}</div>
                  <div class="itemTime">{{ item.time }}</div>
                  <div class="itemValue" :style="item.state | formateState">
                    {{ item.state }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="middle">
            <div class="sliderLine">
              <div class="slider"></div>
            </div>
          </div>
          <div class="right">
            <div class="title">
              <p class="tit">历史采液量及能耗</p>
              <p class="unit">采液量单位：立方米<span>能耗单位：kWh</span></p>
            </div>
            <history-oil-prod-chart
              v-if="flag.historyProdPower"
              :historyInfo="historyProdPowerInfo"
            ></history-oil-prod-chart>
          </div>
        </div>
      </div>
      <div class="rightFrame">
        <!-- 设备运行监控 -->
        <oil-title>
          <span slot="left">设备运行监控</span>
        </oil-title>
        <oil-line-chart
          v-if="flag.oilEleWell"
          :lineChartTheme="oilElectric"
          :historyInfo="oilElectricInfo"
        ></oil-line-chart>
        <oil-line-chart
          v-if="flag.oilEleWell"
          :lineChartTheme="oilWell"
          :historyInfo="oilWellInfo"
        ></oil-line-chart>
        <!-- 三日天气预报 -->
        <div class="threeWeather">
          <oil-title>
            <span slot="left">三日天气预报</span>
          </oil-title>
          <div class="weatherList" @click="test">
            <div
              class="listItem"
              v-for="(item, index) in weatherInfo"
              :key="index"
            >
              <img class="itemIcon" :src="item.weather | formatWeather" />
              <div class="itemDate">
                <p class="date">{{ item.week | formatWeek }} {{ item.date }}</p>
                <p class="level">{{ item.wind }}风{{ item.power }}级</p>
              </div>
              <div class="itemValue">
                {{ item.daytemp }}℃~{{ item.nighttemp }}℃
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="historyDialog"
      title=""
      :visible.sync="dialogOpen"
      width="1000px"
      append-to-body
    >
      <div style="display: flex">
        <oil-title style="margin-left: 10px">
          <span slot="left">设备运行监控</span>
        </oil-title>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          style="margin-left: 30px"
        >
        </el-date-picker>
        <div class="export_btn" @click="exportHistory">导 出</div>
      </div>
      <history-line-chart
        ref="historyChart"
        class="historyChart"
        :dateRange="dateRange"
        :deviceName="headerInfo.device_name"
      />
    </el-dialog>
  </div>
</template>

<script>
import OilHeader from 'components/oilHeader/OilHeader.vue'
import OilTitle from 'components/oilTitle/OilTitle.vue'
import HistoryOilProdChart from 'components/oilBarChart/HistoryOilProdChart.vue'
import OilLineChart from 'components/oilLineChart/OilLineChart.vue'
import InputPwPopup from 'components/popup/InputPwPopup.vue'
import InputModifyPopup from 'components/popup/InputModifyPopup.vue'
import HistoryLineChart from 'components/oilLineChart/HistoryLineChart.vue'

import commonJS from 'assets/js/common.js'
import singleMachineJS from 'network/machine/singleMachine.js'

export default {
  name: 'SingleMachine',
  components: {
    OilHeader,
    OilTitle,
    HistoryOilProdChart,
    OilLineChart,
    HistoryLineChart,
    InputPwPopup,
    InputModifyPopup,
  },
  data() {
    return {
      dialogOpen: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '近七天',
            onClick(picker) {
              const end = new Date()
              const start = new Date(end.getTime() - 7 * 24 * 3600 * 1000)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date(end.getTime() - 30 * 24 * 3600 * 1000)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近半年',
            onClick(picker) {
              const end = new Date()
              const start = new Date(end.getTime() - 180 * 24 * 3600 * 1000)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      dateRange: [new Date(new Date().getTime() - 6 * 3600 * 1000), new Date()],
      headerInfo: {
        name: '智慧抽油监控系统',
        device_name: '',
        nickname: '',
        oilfield: '',
      },
      progress: {
        width: 82,
        stroke: 9,
        centage: 80,
      },
      mainG: {
        false: '~assets/img/singleMachine/icon-mainG-false.png',
        true: '~assets/img/singleMachine/icon-mainG-true.png',
      },
      monitorTimer: null,
      alarmTimer: null,
      historyTimer: null,
      timer: {
        start: null,
        fiveS: null,
        sixH: null,
      },
      params: {},
      flag: { historyProdPower: false, oilEleWell: false },
      monitorInfo: {},
      alarmInfo: [],
      historyProdPowerInfo: {},
      oilElectricInfo: {},
      oilWellInfo: {},
      weatherInfo: [],
      oilElectric: {
        legend: ['电流', '电压'],
        series: [
          {
            itemColor: '#55CB58', // 折点颜色
            lineColor: '#55CB58', // 折线颜色
            areaColor: 'RGBA(0, 100, 150, 1)', // 区域颜色
          },
          {
            itemColor: '#F26163',
            lineColor: '#F26163',
            areaColor: 'rgba(242, 97, 99, 0.2)',
          },
        ],
      },
      oilWell: {
        legend: ['井下压力', '井下温度'],
        series: [
          {
            itemColor: '#55CB58', // 折点颜色
            lineColor: '#55CB58', // 折线颜色
            areaColor: 'RGBA(0, 100, 150, 1)', // 区域颜色
          },
          {
            itemColor: '#F26163',
            lineColor: '#F26163',
            areaColor: 'rgba(242, 97, 99, 0.2)',
          },
        ],
      },
    }
  },
  methods: {
    exportHistory() {
      this.$refs.historyChart.exportHistory()
    },
    /**
     *  事件监听
     */
    test() {},
    start() {
      this.$refs.pwPopup.val = ''
      this.$refs.pwPopup.opt = 'start'
      this.$refs.pwPopup.show()
    },
    stop() {
      this.$refs.pwPopup.val = ''
      this.$refs.pwPopup.opt = 'stop'
      this.$refs.pwPopup.show()
    },
    uplink() {
      this.$refs.mdPopup.tips.cur =
        '当前上行频率为：' + this.monitorInfo.Uplink_F + 'Hz'
      this.$refs.mdPopup.text = '修改上行频率'
      this.$refs.mdPopup.opt = 'uplink'
      this.mdPoupReset()
    },
    downlink() {
      this.$refs.mdPopup.tips.cur =
        '当前下行频率为：' + this.monitorInfo.Downlink_F + 'Hz'
      this.$refs.mdPopup.text = '修改下行频率'
      this.$refs.mdPopup.opt = 'downlink'
      this.mdPoupReset()
    },
    stroke() {
      this.$refs.mdPopup.tips.cur =
        '当前冲次为：' + this.monitorInfo.Stroke_T + '次/min'
      this.$refs.mdPopup.text = '修改冲次'
      this.$refs.mdPopup.opt = 'stroke'
      this.mdPoupReset()
    },
    mdPoupReset() {
      this.$refs.mdPopup.val = ''
      this.$refs.mdPopup.num = ''
      this.$refs.mdPopup.show()
    },
    // 刷新数据
    refresh() {
      this.getDeviceMonitor()
      this.getDeviceAlarm()
      this.getDeviceHistoryIaVaDownhole()
      this.getDeviceHistoryProdPower()
      this.getDeviceWeather()
    },
    // 历史数据
    history() {
      this.dialogOpen = true
    },
    // 发送启动/停止
    handleYesEvent(v) {
      console.log('v', v)
      switch (v) {
        case 'start':
          this.params.password = this.$md5(this.$refs.pwPopup.val)
          this.params.control_type = 'start'
          this.sendModifyEvent(this.params)
          break
        case 'stop':
          this.params.password = this.$md5(this.$refs.pwPopup.val)
          this.params.control_type = 'stop'
          this.sendModifyEvent(this.params)
          break
        default:
          break
      }
    },
    // 发送上行/下行/冲次
    handleModifyEvent(v) {
      switch (v) {
        case 'uplink':
          this.params.password = this.$md5(this.$refs.mdPopup.val)
          this.params.value = parseFloat(this.$refs.mdPopup.num)
          this.params.control_type = 'uplink'
          this.sendModifyEvent(this.params)
          break
        case 'downlink':
          this.params.password = this.$md5(this.$refs.mdPopup.val)
          this.params.value = parseFloat(this.$refs.mdPopup.num)
          this.params.control_type = 'downlink'
          this.sendModifyEvent(this.params)
          break
        case 'stroke':
          this.params.password = this.$md5(this.$refs.mdPopup.val)
          this.params.value = parseFloat(this.$refs.mdPopup.num)
          this.params.control_type = 'stroke'
          this.sendModifyEvent(this.params)
          break
        default:
          break
      }
    },
    // 修改操作发送网络请求
    sendModifyEvent() {
      singleMachineJS.deviceControl(this.params).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          this.$refs.pwPopup.hide()
          this.$refs.mdPopup.hide()
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    /**
     *  网络请求
     */
    // 修改操作发送网络请求
    sendStartStopEven() {
      singleMachineJS.deviceControl(this.params).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          this.$refs.pwPopup.hide()
          this.getDeviceMonitor()
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    // 定时获取5s
    fiveSecondsTimer() {
      this.timer.fiveS = setInterval(() => {
        this.getDeviceMonitor()
        this.getDeviceAlarm()
        this.getDeviceHistoryIaVaDownhole()
      }, 1000 * 5)
    },
    sixHoursTimer() {
      this.timer.sixH = setInterval(() => {
        this.getDeviceHistoryProdPower()
        this.getDeviceWeather()
      }, 1000 * 60 * 60 * 6)
    },
    // 获取基本监控信息
    getDeviceMonitor() {
      singleMachineJS.deviceMonitor(this.params).then((res) => {
        if (res.code == 0) {
          this.monitorInfo = res.monitor_info
          this.progress.centage = this.monitorInfo.Control_T

          this.headerInfo.nickname = res.monitor_info.nickname
          this.headerInfo.oilfield = res.monitor_info.oilfield_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    // 获取故障报警信息
    getDeviceAlarm() {
      singleMachineJS.deviceAlarm(this.params).then((res) => {
        if (res.code == 0) {
          this.alarmInfo = res.alarm_info
          this.alarmPx = res.alarm_info.length * 28
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    // 获取历史采液量及能耗
    getDeviceHistoryProdPower() {
      singleMachineJS.deviceHistoryProdPower(this.params).then((res) => {
        if (res.code == 0) {
          this.flag.historyProdPower = true
          this.historyProdPowerInfo = res.history_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    // 获取设备运行运行监控
    getDeviceHistoryIaVaDownhole() {
      singleMachineJS.deviceHistoryIaVaDownhole(this.params).then((res) => {
        if (res.code == 0) {
          this.flag.oilEleWell = true
          this.oilElectricInfo = {
            time: res.history_info.Time,
            yFirstData: res.history_info.DC_Main_Ia,
            ySecondData: res.history_info.DC_Va,
          }
          this.oilWellInfo = {
            time: res.history_info.Time,
            yFirstData: res.history_info.Downhole_P,
            ySecondData: res.history_info.Downhole_T,
          }
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    // 获取三日天气
    getDeviceWeather() {
      singleMachineJS.deviceWeather(this.params).then((res) => {
        if (res.code == 0) {
          this.weatherInfo = res.weather_info
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 0,
            message: res.msg,
            type: 'warning',
          })
        }
      })
    },
    /**
     *  其它方法
     */

    // 指定进度条文字内容
    stateProgressFun(percentage) {
      return percentage + '°C' + '\n' + '温度'
    },
  },
  filters: {
    // 工作状态转换
    formatWorkS(value) {
      let str = ''
      switch (value) {
        case 1:
          str = '上行状态'
          break
        case 2:
          str = '等待状态'
          break
        case 4:
          str = '下行状态'
          break
        case 8:
          str = '结束状态'
          break
        default:
          str = value
      }
      return str
    },
    // 星期转换
    formatWeek(value) {
      const arr = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      return arr[value - 1]
    },
    // 天气转换
    formatWeather(value) {
      return commonJS.formatWeather(value)
    },
    // 故障状态转换
    formateState(value) {
      return value == '故障' ? { color: '#FF3232' } : { color: '#27e54a' }
    },
    // 故障状态转换
    mainGState(value) {
      // const f = '~assets/img/singleMachine/icon-mainG-false.png'
      // const t = '~assets/img/singleMachine/icon-mainG-true.png'
      // return value == 0
      //   ? { background: 'url(' + f + ')' }
      //   : { background: 'url(' + t + ')' }
      const f = require('assets/img/singleMachine/icon-mainG-false.png')
      const t = require('assets/img/singleMachine/icon-mainG-true.png')
      return value == 0
        ? { background: 'url(' + f + ')' }
        : { background: 'url(' + t + ')' }
    },
    prechargeState(value) {
      const f = require('assets/img/singleMachine/icon-precharge-false.png')
      const t = require('assets/img/singleMachine/icon-precharge-true.png')
      return value == 0
        ? { background: 'url(' + f + ')' }
        : { background: 'url(' + t + ')' }
    },
    fanGState(value) {
      const f = require('assets/img/singleMachine/icon-fanG-false.png')
      const t = require('assets/img/singleMachine/icon-fanG-true.png')
      return value == 0
        ? { background: 'url(' + f + ')' }
        : { background: 'url(' + t + ')' }
    },
  },
  mounted() {
    this.getDeviceMonitor()
    this.getDeviceAlarm()
    this.getDeviceHistoryProdPower()
    this.getDeviceHistoryIaVaDownhole()
    this.getDeviceWeather()
    // 定时器
    this.timer.start = setInterval(() => {
      if (this.flag.historyProdPower && this.flag.oilEleWell) {
        clearInterval(this.timer.start)
        this.timer.start = null
        this.fiveSecondsTimer()
        this.sixHoursTimer()
      }
    }, 300)
  },
  created() {
    console.log(this.$route.query.device_name)
    this.headerInfo.device_name = this.$route.query.device_name
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
    this.params = {
      session_id: this.$store.state.loginInfo.session_id,
      device_name: this.$route.query.device_name,
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timer.fiveS)
    window.clearInterval(this.timer.sixH)
    this.timer.fiveS = null
    this.timer.sixH = null
  },
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.setOffColor {
  color: #019ee5;
}
.setOnColor {
  color: #a5e3ff;
}

.page {
  width: 100%;
  min-height: 100vh;
  min-width: 1200px;
  background: url('~assets/img/common/bg.png');
  background-size: cover;
  .wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
  }
  .leftFrame {
    margin: 19px 0px 25px 19px;
    width: 386px;
    height: auto;
    .machineState {
      width: inherit;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .msInfo {
        margin-top: 26px;
        width: 358px;
        height: 211px;
        background: url('~assets/img/singleMachine/state-bg.png');
        background-size: cover;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        .left,
        .right {
          width: 112px;
          height: 231px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .up,
          .down {
            width: 194px;
            height: 49%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .num {
              width: auto;
              height: 26px;
              font-size: 26px;
              font-family: SourceHanSansCN-Bold;
              font-weight: bold;
              font-style: italic;
              color: #ffffff;
            }
            .text {
              width: auto;
              height: 12px;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              span {
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
        }
        .left,
        .right {
          .up {
            .text {
              margin-top: 13px;
            }
          }
          .down {
            .num {
              margin-top: 13px;
            }
          }
        }
        .right {
          .down {
            position: relative;
            text-align: right;
            .iconU {
              position: absolute;
              left: 70px;
              top: 25px;
              width: auto;
              height: 12px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #32edaa;
              i {
                font-weight: bold;
              }
            }
            .iconD {
              position: absolute;
              left: 68px;
              bottom: 40px;
              width: auto;
              height: 11px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: red;
              i {
                font-weight: bold;
              }
            }
          }
        }
        .middle {
          width: 134px;
          height: 163px;
          display: flex;
          justify-content: center;
          align-items: center;
          ::v-deep {
            .el-progress__text {
              margin-left: 4px;
              width: 70px;
              height: 70px;
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: pre;
              text-align: center;
              font-size: 18px !important;
              font-family: SourceHanSansCN-Bold;
              font-weight: bold;
              font-style: italic;
              color: #ffffff;
            }
            svg path:first-child {
              stroke: transparent;
            }
          }
        }
      }
    }
    .machineMonitorInfo {
      margin-top: 26px;
      width: inherit;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .infoList {
        margin-top: 14px;
        width: 380px;
        height: 588px;
        background: rgba(29, 84, 128, 0.5);
        .listHeader {
          width: 380px;
          height: 42px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          background: rgba(29, 84, 128, 0.5);
          .headerName,
          .headerValue {
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ffffff;
          }
          .headerName {
            margin-left: 25px;
            width: 34%;
          }
          .headerValue {
            width: 38%;
          }
        }
        .listBody {
          width: inherit;
          height: 546px;
          .listItem:nth-child(odd) {
            background: rgba(1, 24, 49, 1);
          }
          .listItem:nth-child(even) {
            background: rgba(4, 40, 72, 0.8);
          }
          .listItem {
            width: inherit;
            height: 34.4px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            .itemName {
              margin-left: 25px;
              width: 38%;
              height: 16px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #dedede;
            }
            .itemValue {
              width: 38%;
              height: 16px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .middleFrame {
    margin: 35px 13px 25px 13px;
    width: 1076px;
    height: auto;
    display: flex;
    flex-direction: column;

    .controlSettings {
      position: relative;
      width: inherit;
      height: 580px;
      display: flex;
      .setLeft {
        margin: 90px 0 0 27px;
        width: 150px;
        height: auto;
        .setTips {
          width: 150px;
          height: 8px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-evenly;
          align-items: center;
          .tipsOn {
            width: 8px;
            height: 8px;
            background: #e7ac23;
            border-radius: 50%;
          }
          .tipsOff {
            width: 8px;
            height: 8px;
            background: #019ee5;
            border-radius: 50%;
          }
          .tipsText {
            width: auto;
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .setList {
          margin-top: 20px;
          width: 150px;
          height: 336px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .icon-mainG,
          .icon-precharge,
          .icon-fanG {
            padding-bottom: 18px;
            width: 150px;
            height: 96px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-size: cover;
            span {
              width: auto;
              height: 16px;
              text-align: center;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #fff;
            }
          }
          .icon-mainG {
            background-size: 100% 100% !important;
            background-repeat: no-repeat;
          }
          .icon-precharge {
            background-size: 100% 100% !important;
            background-repeat: no-repeat;
          }
          .icon-fanG {
            background-size: 100% 100% !important;
            background-repeat: no-repeat;
          }
        }
      }
      .setRight {
        width: 835px;
        height: 597px;
        .setList {
          margin-left: 3px;
          width: inherit;
          height: 79px;
          display: flex;
          flex-flow: row nowrap;
          .icon-start,
          .icon-stop,
          .icon-up,
          .icon-down,
          .icon-f {
            margin-left: 17px;
            padding-bottom: 17px;
            width: 131px;
            height: 79px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            cursor: pointer;
          }
          .icon-start {
            background: url('~assets/img/singleMachine/icon-start-false.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .icon-stop {
            background: url('~assets/img/singleMachine/icon-stop-false.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .icon-up {
            background: url('~assets/img/singleMachine/icon-up-false.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .icon-down {
            background: url('~assets/img/singleMachine/icon-down-false.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .icon-f {
            background: url('~assets/img/singleMachine/icon-f-false.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
        .oilBg {
          margin-top: 20px;
          width: 835px;
          height: 482px;
          background: url('~assets/img/singleMachine/oil-bg.png');
          background-size: cover;
        }
      }
      .back {
        position: absolute;
        top: 0px;
        right: 0px;
        width: auto;
        height: 49px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .opt {
          width: 20px;
          height: 20px;
          background: url('~assets/img/common/back.png');
          background-size: cover;
        }
        .text {
          margin-left: 10px;
          width: auto;
          height: 20px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .refresh {
        position: absolute;
        bottom: 70px;
        right: 0px;
        width: auto;
        height: 45px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .opt {
          width: 45px;
          height: 45px;
          background: url('~assets/img/singleMachine/refresh-btn.png');
          background-size: cover;
        }
        .text {
          width: auto;
          height: 45px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 45px;
        }
      }
      .history {
        position: absolute;
        bottom: 30px;
        right: 0px;
        width: auto;
        height: 45px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .opt {
          width: 45px;
          height: 45px;
          background: url('~assets/img/singleMachine/history-btn.png');
          background-size: cover;
        }
        .text {
          width: auto;
          height: 45px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 45px;
        }
      }
    }
    .controlCharts {
      width: 1076px;
      height: auto;
      display: flex;
      .oilBarChart {
        margin: 12px 0 0 7px;
      }
      .left {
        margin-left: 14px;
        width: 382px;
        height: 357px;
        .title {
          width: 382px;
          height: 42px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          letter-spacing: 1px;
          background: url('~assets/img/singleMachine/header-bg.png');
          background-size: cover;
          p {
            margin-left: 69px;
            width: auto;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f9fafa;
            line-height: 11px;
          }
        }
        .alarmList {
          margin-top: 12px;
          width: 376px;
          height: 303px;
          background: #011831;
          .listHeader {
            width: 376px;
            height: 36px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            background: rgba(29, 84, 128, 0.5);
            .headerName,
            .headerTime {
              height: 14px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
            }
            .headerValue {
              height: 14px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffc600;
            }
            .headerName {
              margin-left: 18px;
              width: 35%;
            }
            .headerTime {
              width: 27%;
            }
            .headerValue {
              margin-right: 14px;
              width: 16.5%;
            }
          }
          .listBody {
            width: 376px;
            height: 267px;
            .listItem:nth-child(odd) {
              background: rgba(1, 24, 49, 1);
            }
            .listItem:nth-child(even) {
              background: rgba(4, 40, 72, 0.8);
            }
            .listItem {
              width: 376px;
              height: 38px;
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
              align-items: center;
              .itemName,
              .itemTime {
                height: 12px;
                font-size: 12px;
                text-align: left;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #7bbcee;
              }
              .itemName {
                margin-left: 18px;
                width: 35%;
              }
              .itemTime {
                width: 32%;
              }
              .itemValue {
                margin-right: 14px;
                width: 15%;
                text-align: left;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
        }
      }
      .middle {
        position: relative;
        width: 31px;
        height: 357px;
        background: url('~assets/img/singleMachine/oil-bg2.png');
        background-size: cover;
        .sliderLine {
          position: absolute;
          bottom: 0;
          width: 31px;
          height: 175px;
          overflow: hidden;
        }
        .slider {
          position: absolute;
          left: 31%;
          bottom: 0px;
          width: 12px;
          height: 38px;
          background: url('~assets/img/singleMachine/oil-silder.png');
          background-size: cover;
          animation: sliderMove 3s linear infinite;
        }
      }
      .right {
        width: 656px;
        height: 357px;
        .historyOilProdChart {
          margin-top: 10px;
        }
        .title {
          width: 656px;
          height: 43px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          letter-spacing: 1px;
          background: url('~assets/img/singleMachine/header-bg2.png');
          background-size: cover;
          .tit {
            margin-left: 69px;
            width: auto;
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #f9fafa;
            line-height: 11px;
          }
          .unit {
            width: auto;
            height: 16px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(249, 250, 250, 0.64);
            line-height: 11px;
            span {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  .rightFrame {
    margin: 22px 0px 0 9px;
    width: 386px;
    height: auto;
    .threeWeather {
      margin-top: 21px;
      width: 386px;
      height: 357px;
      .weatherList {
        margin-top: 11px;
        width: 386px;
        height: 303px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .listItem {
          width: 380px;
          height: 88px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          background: rgba(23, 78, 128, 0.22);
          .itemIcon {
            margin-left: 18px;
            width: auto;
            height: auto;
          }
          .itemDate {
            min-width: 120px;
            height: 56px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          .itemValue {
            margin-right: 18px;
            width: 122px;
            height: 24px;
            width: 122px;
            font-size: 24px;
            font-family: SourceHanSansCN-Bold;
            color: #00b0ff;
          }
        }
      }
    }
  }
}

.historyDialog {
  /deep/ .el-dialog {
    background: #011831;
    border-radius: 0;
  }
  /deep/ .el-dialog__header {
    background: transparent;
  }
  /deep/ .el-dialog__body {
    background: transparent;
    padding: 0;
  }
  .export_btn {
    margin-left: 30px;
    width: 60px;
    height: 40px;
    color: #ffffff;
    background: #123456;
    border-color: #285787;
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
  }
  .export_btn:hover {
    cursor: pointer;
  }
  .historyChart {
    margin-top: 10px;
  }
}

@keyframes sliderMove {
  from {
    bottom: -14%;
  }
  to {
    bottom: 100%;
    height: 38px;
  }
}
</style>
