export const dataKeys = [
  'Uplink_F',
  'Downlink_F',
  'Stroke_T',
  'DC_Main_Ia',
  'M_A_Ie',
  'M_B_Ie',
  'M_C_Ie',
  'DC_Va',
  'Input_Ve',
  'Radiator_T',
  'Control_T',
  'P15V_V',
  'N15V_V',
  'P5V_V',
  'Downhole_T',
  'Downhole_P'
];

const dataConfig = {
  Uplink_F: {
    name: '上行频率',
    show: false,
    itemColor: '#FF5733',
    lineColor: '#FF5733',
    areaColor: 'rgba(255, 87, 51, 0.1)'
  },
  Downlink_F: {
    name: '下行频率',
    show: false,
    itemColor: '#DAF7A6',
    lineColor: '#DAF7A6',
    areaColor: 'rgba(218, 247, 166, 0.1)'
  },
  Stroke_T: {
    name: '冲次',
    show: false,
    itemColor: '#FFC300',
    lineColor: '#FFC300',
    areaColor: 'rgba(255, 195, 0, 0.1)'
  },
  DC_Main_Ia: {
    name: '直流母线电流平均值',
    show: true,
    itemColor: '#9C27B0',
    lineColor: '#9C27B0',
    areaColor: 'rgba(156, 39, 176, 0.1)'
  },
  M_A_Ie: {
    name: '电机A相电流有效值',
    show: false,
    itemColor: '#E91E63',
    lineColor: '#E91E63',
    areaColor: 'rgba(233, 30, 99, 0.1)'
  },
  M_B_Ie: {
    name: '电机B相电流有效值',
    show: false,
    itemColor: '#3F51B5',
    lineColor: '#3F51B5',
    areaColor: 'rgba(63, 81, 181, 0.1)'
  },
  M_C_Ie: {
    name: '电机C相电流有效值',
    show: false,
    itemColor: '#00BCD4',
    lineColor: '#00BCD4',
    areaColor: 'rgba(0, 188, 212, 0.1)'
  },
  DC_Va: {
    name: '直流电压平均值',
    show: false,
    itemColor: '#4CAF50',
    lineColor: '#4CAF50',
    areaColor: 'rgba(76, 175, 80, 0.1)'
  },
  Input_Ve: {
    name: '输入电压有效值',
    show: false,
    itemColor: '#F44336',
    lineColor: '#F44336',
    areaColor: 'rgba(244, 67, 54, 0.1)'
  },
  Radiator_T: {
    name: '散热器温度',
    show: false,
    itemColor: '#FFEB3B',
    lineColor: '#FFEB3B',
    areaColor: 'rgba(255, 235, 59, 0.1)'
  },
  Control_T: {
    name: '控制柜温度',
    show: false,
    itemColor: '#2196F3',
    lineColor: '#2196F3',
    areaColor: 'rgba(33, 150, 243, 0.1)'
  },
  P15V_V: {
    name: 'P15V控制电源电压',
    show: false,
    itemColor: '#009688',
    lineColor: '#009688',
    areaColor: 'rgba(0, 150, 136, 0.1)'
  },
  N15V_V: {
    name: 'N15V控制电源电压',
    show: false,
    itemColor: '#CDDC39',
    lineColor: '#CDDC39',
    areaColor: 'rgba(205, 220, 57, 0.1)'
  },
  P5V_V: {
    name: 'P5V控制电源电压',
    show: false,
    itemColor: '#673AB7',
    lineColor: '#673AB7',
    areaColor: 'rgba(103, 58, 183, 0.1)'
  },
  Downhole_T: {
    name: '井下温度',
    show: true,
    itemColor: '#03A9F4',
    lineColor: '#03A9F4',
    areaColor: 'rgba(3, 169, 244, 0.1)'
  },
  Downhole_P: {
    name: '井下压力',
    show: true,
    itemColor: '#8BC34A',
    lineColor: '#8BC34A',
    areaColor: 'rgba(139, 195, 74, 0.1)'
  }
};

export function getOption(data, echarts, start, end) {
  const option = {
    grid: {
      height: '75%',
      top: '21%',
      left: '2%',
      right: '2%',
      bottom: '0%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: [],
      top: '4%',
      right: '3%',
      textStyle: {
        color: 'rgba(255, 255, 255, 1)',
        fontFamily: 'Source Han Sans CN'
      },
      selected: {}
    },
    xAxis: [
      {
        type: 'time',
        boundaryGap: '0%',
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(124, 197, 247, 0.3)' // 坐标轴线线的颜色
          }
        },
        axisTick: {
          show: false // 去除刻度线
        },
        axisLabel: {
          // 坐标轴刻度标签的相关设置
          color: '#7CC5F7',
          fontFamily: 'Source Han Sans CN',
          fontSize: 12
        }
      }
    ],
    yAxis: [],
    series: []
  };

  data = data || {};
  const currentDataKeys = [];
  const legendData = [];
  const yAxisData = [];
  const seriesData = [];
  const num = data.time.length;
  let index = 0;
  for (const key of dataKeys) {
    if (data.hasOwnProperty(key) && data[key].length === num) {
      currentDataKeys.push(key);

      const name = dataConfig[key].name;
      legendData.push(name);
      option.legend.selected[name] = dataConfig[key].show;

      const defaultYAxis = {
        type: 'value',
        axisLabel: {
          // 坐标轴刻度标签的相关设置
          show: false
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: 'rgba(124, 197, 247, 0.3)'
          }
        }
      };

      if (key === 'DC_Main_Ia') {
        defaultYAxis.splitLine.show = true;
      }
      yAxisData.push(defaultYAxis);

      const lineData = [];
      for (let i = 0; i < num; i++) {
        lineData.push({
          value: [new Date(data.time[i]).getTime(), data[key][i]]
        });
      }
      const line = {
        name: dataConfig[key].name,
        type: 'line',
        stack: key,
        symbol: 'none',
        symbolSize: 2,
        itemStyle: {
          normal: {
            color: dataConfig[key].itemColor, // 折点颜色
            lineStyle: {
              width: 1,
              color: dataConfig[key].lineColor // 折线颜色
            }
          }
        },
        areaStyle: {
          color: dataConfig[key].areaColor
        },
        data: lineData,
        yAxisIndex: index,
        show: dataConfig[key].show
      };
      option.series.push(line);
      index++;
    }
  }

  // 构造表头
  option.legend.data = legendData;

  // 构造y轴
  option.yAxis = yAxisData;

  return option;
}
