<template>
  <div class="oilLineChart" ref="oilLineChart"></div>
</template>

<script>
import ChartTimeJson from 'assets/json/chartTIme.json'
import { getOption } from './data.js'
import singleMachineJS from 'network/machine/singleMachine.js'

export default {
  name: 'HistoryLineChart',
  props: {
    dateRange: Array,
    deviceName: String,
  },
  data: () => {
    return {
      chartInstance: null,
      chartTimeList: '',
      option: {},
      maxPieces: 500,
      minRangeSecond: 60,
      canExport: false,
      data: undefined,
    }
  },
  watch: {
    dateRange() {
      this.updateChart()
    },
  },
  methods: {
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.oilLineChart)
      this.updateChart()
    },
    // 更新图表
    updateChart() {
      if (!this.dateRange) {
        return
      }
      const start = this.dateRange[0].getTime() / 1000
      const end = this.dateRange[1].getTime() / 1000
      let pieces = Math.floor((end - start) / this.minRangeSecond)
      if (pieces > 0) {
        pieces = Math.min(pieces, this.maxPieces)
      } else {
        return
      }
      console.log('start:', start, 'end:', end, 'pieces:', pieces)
      this.canExport = false
      this.chartShowLoading()
      this.data = {
        session_id: this.$store.state.loginInfo.session_id,
        device_name: this.deviceName,
        pieces: pieces,
        from_time: this.dateFormatter(this.dateRange[0]),
        to_time: this.dateFormatter(this.dateRange[1]),
      }
      singleMachineJS.historyAll(this.data).then((res) => {
        if (res.code == 0) {
          this.option = getOption(res.history_info, this.chartInstance)
          this.chartInstance.hideLoading()
          this.chartInstance.setOption(this.option)
          this.canExport = true
        } else {
          this.$message.error('res.msg')
        }
      })
    },
    dateFormatter(date) {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // 月份从0开始计数，所以加1
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const seconds = date.getSeconds().toString().padStart(2, '0')

      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      console.log(formattedDate) // 输出形如 "2024-04-19 14:55:30"
      return formattedDate
    },
    // 获取背景阴影的json
    getChartTimeJson() {
      this.chartTimeList = ChartTimeJson.list
    },
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0,
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('oilBarChart'))
          .resize()
      }
    },
    exportHistory() {
      if (this.canExport) {
        this.data.download = true
        singleMachineJS.historyAll(this.data).then((res) => {
          if (res.code == 0) {
            // 创建一个动态的 `a` 标签
            const link = document.createElement('a')
            link.href = res.url
            // link.setAttribute('download', fileName) // 这里设置下载属性以及下载时的文件名
            document.body.appendChild(link) // 将 `a` 标签添加到文档中去
            link.click() // 模拟点击实现自动下载
            document.body.removeChild(link) // 下载后移除元素
            this.$message.success('导出成功')
          } else {
            this.$message.error('res.msg')
          }
        })
      } else {
        this.$message.error('暂无数据导出')
      }
    },
  },
  mounted() {
    this.initChart()
  },
  created() {},
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.oilLineChart {
  width: 1000px;
  height: 600px;
  background: rgba(23, 78, 128, 0.22);
}
</style>
