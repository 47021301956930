import { request } from "../request";

// 基本监控信息
const deviceMonitor = function(params) {
  return request({
    url: "api/device/monitor",
    method: "post",
    data: params
  });
};

// 故障报警信息
const deviceAlarm = function(params) {
  return request({
    url: "api/device/alarm",
    method: "post",
    data: params
  });
};
// 历史采液量及能耗
const deviceHistoryProdPower = function(params) {
  return request({
    url: "api/device/history_production_power",
    method: "post",
    data: params
  });
};
// 设备运行运行监控 电流、电压、井下压力和井下温度
const deviceHistoryIaVaDownhole = function(params) {
  return request({
    url: "api/device/history_ia_va_downhole",
    method: "post",
    data: params
  });
};
// 三日天气预报
const deviceWeather = function(params) {
  return request({
    url: "api/device/weather",
    method: "post",
    data: params
  });
};
// 设备控制
const deviceControl = function(params) {
  return request({
    url: "api/device/control",
    method: "post",
    data: params
  });
};
// 历史数据
const historyAll = function(params) {
  return request({
    url: "api/device/history_all",
    method: "post",
    data: params
  });
};

export default {
  deviceMonitor,
  deviceAlarm,
  deviceHistoryProdPower,
  deviceHistoryIaVaDownhole,
  deviceWeather,
  deviceControl,
  historyAll
};
