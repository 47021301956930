<template>
  <div class="historyOilProdChart" ref="historyOilProdChart"></div>
</template>

<script>
export default {
  name: 'HistoryOilProdChart',
  props: {
    historyInfo: Object
  },
  data: () => {
    return {
      chartInstance: null
    }
  },
  watch: {
    historyInfo() {
      this.updateChart()
    }
  },
  methods: {
    // 初始化echartInstance对象
    initChart() {
      this.chartInstance = this.$echarts.init(this.$refs.historyOilProdChart)
      this.chartShowLoading()
      this.updateChart()
    },
    // 更新图表
    updateChart() {
      // const xDataArr = [
      //   '07.01',
      //   '07.02',
      //   '07.03',
      //   '07.04',
      //   '07.05',
      //   '07.06',
      //   '07.07'
      // ]
      // const yDataArr1 = [460, 92, 63, 377, 294, 80, 72]
      // const yDataArr2 = [112, 60, 161, 62, 185, 279, 92]
      const option = {
        grid: {
          height: '75%',
          top: '21%',
          left: '3%',
          right: '3%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          top: '4%',
          right: '3%',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)',
            fontFamily: 'Source Han Sans CN'
          }
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.historyInfo.Date,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(115, 194, 220, 0.3)' // 坐标轴线线的颜色
            }
          },
          axisTick: {
            show: false // 去除刻度线
          },
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            color: '#7CC5F7',
            fontFamily: 'Source Han Sans CN',
            fontSize: 12
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed', // 设置网格线类型
              color: 'rgba(41, 92, 136, 1)'
            }
          },
          axisLabel: {
            // 坐标轴刻度标签的相关设置
            color: '#7CC5F7',
            fontFamily: 'Source Han Sans CN',
            fontSize: 12
          }
        },
        series: [
          {
            name: '采液量',
            type: 'bar',
            data: this.historyInfo.T_Production,
            barWidth: 10,
            barGap: 1, // 间隔
            itemStyle: {
              normal: {
                label: {
                  // 标签设置
                  show: true,
                  position: 'top',
                  color: 'rgba(241, 196, 42, 1)',
                  fontSize: 12,
                  fontFamily: 'Source Han Sans CN'
                },
                barBorderRadius: 3, // 圆角
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    // 背景渐变色
                    offset: 0,
                    color: 'rgba(242, 197, 42, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(156, 76, 5, 1)'
                  }
                ])
              }
            }
          },
          {
            name: '能耗',
            type: 'bar',
            data: this.historyInfo.T_PowerC,
            barWidth: 10,
            itemStyle: {
              normal: {
                label: {
                  // 标签设置
                  show: true,
                  position: 'top',
                  color: 'rgba(0, 192, 255, 1)',
                  fontSize: 12,
                  fontFamily: 'Source Han Sans CN'
                },
                barBorderRadius: 3, // 圆角
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    // 背景渐变色
                    offset: 0,
                    color: 'rgba(0, 192, 255, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(6, 117, 253, 1)'
                  }
                ])
              }
            }
          }
        ]
      }
      this.chartInstance.hideLoading()
      this.chartInstance.setOption(option)
    },
    // 图表showLoading
    chartShowLoading() {
      this.chartInstance.showLoading({
        text: 'loading',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
    },
    // 图表自适应
    chartResize() {
      window.onresize = () => {
        this.$echarts
          .init(document.getElementsByClassName('historyOilProdChart'))
          .resize()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.historyOilProdChart {
  width: 650px;
  height: 303px;
  background: rgba(23, 78, 128, 0.22);
}
</style>
