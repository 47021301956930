<template>
  <div class="inputPwPopup" style="z-index: 3" v-show="visible">
    <div class="mask"></div>
    <div class="popup">
      <div class="title">
        <p class="text">输入密码</p>
        <div class="exit" @click="hide">
          <i class="iconfont icon-cuowu"></i>
        </div>
      </div>
      <input
        class="password"
        type="password"
        v-model="val"
        placeholder="请输入密码"
      />
      <p class="tips">{{ tips }}</p>
      <button class="yes" type="button" @click="yes">确认</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputPwPopup',
  data: () => {
    return {
      visible: false,
      tips: '',
      val: '',
      opt: '' // 父组件执行的操作
    }
  },
  methods: {
    yes() {
      if (this.val == '') {
        this.tips = '密码不能为空'
        return false
      }
      this.$emit('handleYesEvent', this.opt)
    },
    show() {
      console.log('opt', this.opt)
      this.visible = true
    },
    hide() {
      this.tips = ''
      this.visible = false
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.inputPwPopup {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  .mask {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #606266;
    opacity: 0.7;
  }
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 20;
    width: 528px;
    height: 323px;
    background: url('~assets/img/common/input-popup-bg.png');
    background-size: cover;
    .title {
      width: 100%;
      height: auto;
      .text {
        margin-top: 8px;
        text-align: center;
        width: auto;
        height: 23px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #019ee5;
        line-height: 60px;
      }
      .exit {
        position: absolute;
        right: 47px;
        top: 12px;
        width: 30px;
        height: 30px;
        text-align: center;
        cursor: pointer;
        &:hover {
          i {
            color: #019ee5;
            transition: 0.3s ease;
          }
        }
        i {
          font-size: 22px;
          color: #046794;
        }
      }
    }
    .password {
      position: absolute;
      bottom: 150px;
      left: 52px;
      padding-left: 23px;
      width: 424px;
      height: 52px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #019ee5;
      line-height: 60px;
      background: transparent;
      border: 1px solid #046794;
      &:focus {
        border-color: rgba(1, 158, 229, 1);
        transition: 0.3s ease;
      }
      &::placeholder {
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: rgba(1, 158, 229, 1);
      }
    }
    .yes {
      position: absolute;
      bottom: 62px;
      left: 52px;
      width: 424px;
      height: 52px;
      line-height: 52px;
      letter-spacing: 2px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      border: none;
      background: #019ee5;
      &:focus {
        outline: 1px solid #046794;
      }
    }
    .tips {
      width: auto;
      height: auto;
      position: absolute;
      bottom: 125px;
      left: 52px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      color: red;
    }
  }
}
</style>
